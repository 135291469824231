import { DateTime, Duration } from "luxon";

export const useDateTime = () => {
  const secondsToTime = (seconds, opts) => {
    const { format, showSuffix } = opts || {};
    // checks if seconds value is more than 1h
    const hourInSeconds = 60 * 60;
    const isMoreThanOneHour = seconds >= hourInSeconds;
    const suffix = isMoreThanOneHour ? "hr" : "min";

    const defaultFormat = isMoreThanOneHour ? "hh:mm:ss" : "mm:ss";

    const duration = Duration.fromObject({ seconds });
    const fmt = format || defaultFormat;

    if (showSuffix) {
      return `${duration.toFormat(fmt)}${suffix}`;
    }

    return duration.toFormat(fmt);
  };

  const isInFuture = (lxnDate) => {
    if (!lxnDate?.isValid) return;
    return lxnDate > DateTime.now();
  };

  /**
   * used in songs pages.
   * format table:
   * 3 minutes, 30 seconds → 3:30 min
   * 45 seconds → 0:45 min
   * 2 minutes → 2:00 min
   * 1 hour, 23 minutes, 12 seconds → 83:12 min
   */
  const asSongDuration = (seconds) => {
    if (isNaN(seconds)) return "";
    const duration = Duration.fromObject({ seconds });
    const obj = duration.shiftTo("minutes", "seconds").toObject();
    let format = "m:ss 'min'";

    if (obj.seconds === 0) {
      format = "m 'min'";
    }

    return duration.toFormat(format);
  };

  const asDurationPretty = (seconds) => {
    const { t } = useI18n();

    const duration = Duration.fromObject({ seconds });

    try {
      const { hours, minutes, seconds } = duration
        .shiftTo("hours", "minutes", "seconds")
        .toObject();

      // /***
      //  * expected results:
      //  * 1hr
      //  * 1hr 5mins
      //  * 4mins 30secs
      //  * 1hr 4mins
      //  */

      const formattedDuration = [];

      if (hours > 0) {
        // formattedDuration.push(t("hours", { h: hours }));
        formattedDuration.push(hours + "h");
      }

      if (minutes > 0) {
        // formattedDuration.push(t("minutes", { m: minutes }));
        formattedDuration.push(minutes + "min");
      }

      // add'l checks whether to include seconds
      if ((hours < 0 || minutes < 0) && seconds > 0) {
        // formattedDuration.push(t("seconds", { s: Math.floor(seconds) }));
        formattedDuration.push(Math.floor(seconds), "secs");
      }

      return formattedDuration.join(" ");
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return { secondsToTime, isInFuture, asDurationPretty, asSongDuration };
};
